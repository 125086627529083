import React, { createContext, useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { COOKIES } from 'day8-react/build/constants';
import { getOrSetABTestGroup } from 'day8-react/build/helpers/querystring';
import { getTYWDestinations } from '../helpers/api';
import { TYWDestinationType } from '../globalTypes';
import { useRouter } from 'next/router';

type Props = {
  children: React.ReactNode
}
type InitialValues = {
  isModalOpen: boolean
  open: () => void
  close: () => void
  toggle: () => void
  clearFilterCookie: () => void
  destinations: TYWDestinationType[]
  showNewFunnel: boolean
  hasFilterCookie: boolean
}
export const initialValues: InitialValues = { isModalOpen: false, open: () => { }, close: () => { }, toggle: () => { }, destinations: [], showNewFunnel: false, hasFilterCookie: false, clearFilterCookie: () => { } };
export const LuckyModalContext = createContext(initialValues);

export const LuckyModalProvider: React.FC<Props> = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [destinations, setDestinations] = useState<[] | []>([]);
  const [showNewFunnel, setShowNewFunnel] = React.useState(false);
  const [hasFilterCookie, setHasFilterCookie] = React.useState(false);
  const router = useRouter();
  useEffect(() => {
    const showNewFunnelCookie = getOrSetABTestGroup({ ...router, location: { search: window?.location?.search } }, COOKIES.FUNNEL_SWITCH);
    setShowNewFunnel(showNewFunnelCookie);
    if (showNewFunnelCookie) {
      // handle filter from cookie
      const cookieData = Cookies.get(COOKIES.FILTER);
      if (cookieData) {
        setHasFilterCookie(true);
      }
      const fetchDestinations = async () => {
        const data = await getTYWDestinations();
        setDestinations(data);
      };
      fetchDestinations();
    }
  }, []);

  const open = () => {
    setIsModalOpen(true);
  };

  const close = () => {
    setIsModalOpen(false);
  };
  const clearFilterCookie = () => {
    Cookies.remove(COOKIES.FILTER);
    setHasFilterCookie(false);
  };

  const toggle = () => {
    setIsModalOpen(!isModalOpen);
  };
  return (
    <LuckyModalContext.Provider value={{ isModalOpen, open, close, toggle, destinations, showNewFunnel, hasFilterCookie, clearFilterCookie }}>
      {children}
    </LuckyModalContext.Provider>
  );
};

export const useLuckyModal = () => useContext(LuckyModalContext);
