import { RangeButtons } from 'day8-react/build/new-components';
import { useEffect, useState } from 'react';
import { LuckyModalFormData } from '../LuckyModal';
import ModalHead from '../components/ModalHead';

type CrewProps = {
  formData: LuckyModalFormData
  handleChange: (e: any) => void
}
const CREW = {
  MAX_GUESTS: 14
};

const Crew: React.FC<CrewProps> = ({ formData, handleChange }) => {
  const [capacity, setCapacity] = useState(formData.capacity);
  useEffect(() => {
    if (capacity !== formData.capacity) {
      handleChange({ target: { value: capacity, name: 'capacity' } });
    }
  }, [capacity, handleChange]);
  return (
    <div>
      <ModalHead icon="male-female" title="Who?" subtitle="Whether there's two of you or a whole group, let us know so we can show the right options for you." />
      <div className="d8-l-auto-grid">
        <div className='flex justify-between items-center width-100%'>
          <div>
            <h3 className='d8-u-font-size-base'>Capacity</h3>
          </div>
          <div>
            <RangeButtons
              minusDisabled={capacity === 0}
              plusDisabled={capacity >= CREW.MAX_GUESTS}
              count={capacity}
              onClick={(operator: number) => setCapacity(capacity + operator)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Crew;