import { COLORS, Card, Svg } from 'day8-react/build/new-components';
import { LuckyModalFormData } from '../LuckyModal';
import { useLuckyModal } from '../../../context/LuckyModalContext';
import ModalHead from '../components/ModalHead';

type DestinationsProps = {
  formData: LuckyModalFormData;
  handleChange: (e: any) => void
}

const Destinations: React.FC<DestinationsProps> = ({ formData, handleChange }) => {
  const { destinations } = useLuckyModal();
  const handleDestinationSelect = (destinationId: number | null) => {
    handleChange({ target: { value: destinationId, name: 'destinationFilter' } });
  };
  const isDestinationActive = (destinationId: number) => formData.destinationFilter === destinationId;
  return (
    <div className='step-destinations'>
      <ModalHead icon="globe" title="Where?" subtitle="Choose a destination or if you're unsure go straight to the next step to see all dates." />
      <div className="grid grid-gap-md margin-y-md" style={{ height: '300px', overflow: 'scroll' }}>
        {destinations.map(destination => (
          <div className='col-12 col-6@sm'>
            <Card
              key={destination.id}
              className={`${isDestinationActive(destination.id) && 'active'}`}
              template="ImageCard"
              size="xs"
              onClick={() => handleDestinationSelect(destination.id)}
              bgImage={{ url: destination.img, alt: destination.name }}
              heading={destination.name}
              subheading={destination.dates}
              whiteFont
              overlay
              hover
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Destinations;