import { Svg, COLORS } from 'day8-react/build/new-components';
import { Fragment } from 'react';

type ModalHeadProps = {
  icon: string,
  title: string,
  subtitle?: string
}
const ModalHead: React.FC<ModalHeadProps> = ({ icon, title, subtitle }) => {
  return (
    <Fragment>
      <div className="margin-bottom-md text-component">
        <h2><Svg name={icon} fill={COLORS.BLACK} className="icon--md margin-right-sm" />{title}</h2>
        {subtitle && <p>{subtitle}</p>}
      </div>
      {/* <hr className="margin-y-md" style={{ height: '1px', background: 'var(--color-contrast-low)' }} /> */}
    </Fragment>
  );
};

export default ModalHead;