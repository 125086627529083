import { BUTTON_TYPES, Button, Modal } from "day8-react/build/new-components";
import { useForm } from 'day8-react';
import qs from 'query-string';
import { useLuckyModal } from "../../context/LuckyModalContext";
import { useState, useMemo } from "react";
import Calendar from "./steps/Calendar";
import Crew from "./steps/Crew";
import Destinations from "./steps/Destinations";
import ModalHead from "./components/ModalHead";
const STEPS = {
  DESTINATIONS: 0,
  CALENDAR: 1,
  CREW: 2,
};
const stepComponents = {
  [STEPS.DESTINATIONS]: Destinations,
  [STEPS.CALENDAR]: Calendar,
  [STEPS.CREW]: Crew
};

export type LuckyModalFormData = {
  month: number | null;
  hostess: number;
  capacity: number;
  yearFilter: number;
  destinationFilter: number;
  cabinBookingType: boolean;
  customYachts: boolean;
}
const LuckyModal = () => {
  const { formData, handleChange } = useForm({
    month: null,
    capacity: 8,
    hostess: 0,
    destinationFilter: 0,
    cabinBookingType: null,
    customYachts: null,
    yearFilter: null
  });
  const [step, setStep] = useState(STEPS.DESTINATIONS);
  const { isModalOpen, toggle, hasFilterCookie, clearFilterCookie } = useLuckyModal();
  const actionLabel = useMemo(() => {
    if (step === Object.keys(STEPS).length - 1) {
      return 'See options';
    }
    return 'Next';
  }, [step]);
  const secondaryActionLabel = useMemo(() => {
    if (step === 0) {
      return undefined;
    }
    return 'Back';
  }, [step]);
  const Component = stepComponents[step];
  const onNext = () => {
    if (step === Object.keys(STEPS).length - 1) {
      return buildQueryParams();
    }
    setStep(value => value + 1);
  };
  const onBack = () => {
    setStep(value => value - 1);
  };
  const buildQueryParams = () => {
    const girls = Math.round(formData.capacity / 2);
    const boys = formData.capacity - girls;
    const query = {
      ...formData,
      girls,
      boys
    };
    delete query['capacity'];
    delete query['customYachts'];
    if (formData.destinationFilter === 0) {
      delete query['destinationFilter'];
    }
    const url = qs.stringifyUrl({
      url: formData.customYachts ? '/browse/yachts' : '/browse',
      query: query
    }, { skipNull: true });
    window.location.href = url;
  };
  if (hasFilterCookie) {
    return (
      <Modal toggle={toggle} on={isModalOpen} noCloseIcon>
        <div className="d8-c-lucky-modal">
          <ModalHead icon="search" title="Continue search?" />
          <div className="flex flex-gap-md justify-end margin-top-lg">
            <Button text={'Clear search'} buttonType={BUTTON_TYPES.OUTLINE_PRIMARY} onClick={clearFilterCookie} fullWidth />
            <Button text={'Continue'} onClick={() => window.location.href = '/browse'} fullWidth />
          </div>
        </div>
      </Modal>
    )
  }
  return (
    <Modal toggle={toggle} on={isModalOpen} noCloseIcon>
      <div className="d8-c-lucky-modal">
        <Component formData={formData} handleChange={handleChange} />
        <div className="margin-top-md flex">
          <hr className="margin-y-md width-100%" style={{ height: '1px', background: 'var(--color-contrast-low)' }} />
        </div>
        <div className="flex flex-gap-md justify-end">
          <Button text={secondaryActionLabel || 'Close'} buttonType={BUTTON_TYPES.OUTLINE_PRIMARY} onClick={() => secondaryActionLabel ? onBack() : toggle()} fullWidth />
          <Button text={actionLabel} onClick={onNext} fullWidth />
        </div>
      </div>
    </Modal>
  );
};

export default LuckyModal;