import {
  Toggle,
  ClickOutside,
  COLORS,
  Card,
  BUTTON_TYPES,
  Button,
  Svg
} from 'day8-react/build/new-components';
import { ToggleType } from '../../../globalTypes';
import { LuckyModalFormData } from '../LuckyModal';
import { useLuckyModal } from '../../../context/LuckyModalContext';
import { useEffect } from 'react';
import ModalHead from '../components/ModalHead';

type CalendarProps = {
  formData: LuckyModalFormData;
  handleChange: (e: any) => void
}
type Month = {
  name: string;
  icon: string;
  id: number;
};

const MONTHS: Month[] = [{ name: 'Jan', icon: 'calendar', id: 0 }, { name: 'Feb', icon: 'calendar', id: 1 }, { name: 'Mar', icon: 'calendar', id: 2 }, { name: 'Apr', icon: 'calendar', id: 3 }, { name: 'May', icon: 'calendar', id: 4 }, { name: 'Jun', icon: 'calendar', id: 5 }, { name: 'Jul', icon: 'calendar', id: 6 }, { name: 'Aug', icon: 'calendar', id: 7 }, { name: 'Sep', icon: 'calendar', id: 8 }, { name: 'Oct', icon: 'calendar', id: 9 }, { name: 'Nov', icon: 'calendar', id: 10 }, { name: 'Dec', icon: 'calendar', id: 11 }];

const Calendar: React.FC<CalendarProps> = ({ formData, handleChange }) => {
  const { destinations } = useLuckyModal();
  const allWeeks = destinations.flatMap(destination => destination.weeks).filter(w => !w.invisible && w.status !== 'sold_out');
  const uniqueYears = [...new Set(allWeeks.map(week => week.year))];
  useEffect(() => {
    if (uniqueYears.length > 0) {
      handleChange({ target: { value: uniqueYears[0], name: 'yearFilter' } });
    }
  }, [])
  const isMonthSelected = (month: number) => formData.month === month;
  const handleMonthSelect = (monthId: number) => {
    handleChange({ target: { value: monthId, name: 'month' } });
  };

  const handleDestinationSelect = (destinationId: number | null, toggle: () => void) => {
    handleChange({ target: { value: destinationId, name: 'destinationFilter' } });
    toggle();
  };
  const handleYearSelect = (year: number | null, toggle: () => void) => {
    handleChange({ target: { value: year, name: 'yearFilter' } });
    toggle();
  };

  const isDestinationActive = (destinationId: number) => formData.destinationFilter === destinationId;
  const getDestinationButtonText = (destinationId: number | null) => destinationId && destinations.find(dest => +dest.id === +destinationId)?.name || 'All destinations';
  const activeDestinationWeeks = formData.destinationFilter && destinations.find(dest => +dest.id === +formData.destinationFilter)?.weeks || [];
  let enabledMonthsForActiveDestination = formData.destinationFilter && activeDestinationWeeks.filter(week => week.year === formData.yearFilter && !['sold_out', 'closed', 'coming_soon'].includes(week.status)).flatMap(week => new Date(week.beginDate).toLocaleString('default', { month: 'short' }));
  if (!formData.destinationFilter) {
    enabledMonthsForActiveDestination = allWeeks.filter(week => week.year === formData.yearFilter).flatMap(week => new Date(week.beginDate).toLocaleString('default', { month: 'short' }));
  }
  const hasMultipleUniqueYears = uniqueYears.length > 1;
  return (
    <div>
      <ModalHead icon="calendar" title="When?" subtitle="If you have a specific month you can travel select here, or skip to the next step to see all options." />
      <div className='grid grid-gap-md'>
        <Toggle>
          {({ on, toggle }: ToggleType) => (
            <div className={`${hasMultipleUniqueYears ? 'col-12 col-6@sm' : 'col-12'} d8-u-relative`}>
              <Button buttonType={BUTTON_TYPES.INPUT} className={`d8-u-overflow-hidden ${!!on && 'active'}`} onClick={toggle} fullWidth text={getDestinationButtonText(formData.destinationFilter)} iconFlip icon={on ? 'minus' : 'arrow-down'} active />
              {on && (
                <ClickOutside onClick={toggle}>
                  <div className="d8-u-absolute width-100% d8-u-zindex-popover margin-top-md">
                    <Card>
                      <div className="d8-u-overflow-auto" style={{ height: '250px' }}>
                        <Button buttonType={BUTTON_TYPES.FILTER_LIST_ITEM} text="All destinations" onClick={() => handleDestinationSelect(null, toggle)} iconFlip icon={formData.destinationFilter === null ? 'checkmark' : ''} active={formData.destinationFilter === null} fullWidth />
                        {destinations.map(destination =>
                          <Button key={destination.id} buttonType={BUTTON_TYPES.FILTER_LIST_ITEM} text={destination.name} onClick={() => handleDestinationSelect(+destination.id, toggle)} iconFlip icon={isDestinationActive(+destination.id) ? 'checkmark' : ''} active={isDestinationActive(+destination.id)} fullWidth />
                        )}
                      </div>
                    </Card>
                  </div>
                </ClickOutside>
              )}
            </div>
          )}
        </Toggle>
        {hasMultipleUniqueYears && (
          <Toggle>
            {({ on, toggle }: ToggleType) => (
              <div className="col-12 col-6@sm d8-u-relative">
                <Button buttonType={BUTTON_TYPES.INPUT} className={`d8-u-overflow-hidden ${!!on && 'active'}`} onClick={toggle} fullWidth text={formData.yearFilter || 'All years'} iconFlip icon={on ? 'minus' : 'arrow-down'} active />
                {on && (
                  <ClickOutside onClick={toggle}>
                    <div className="d8-u-absolute width-100% d8-u-zindex-popover margin-top-md">
                      <Card>
                        <div className="d8-u-overflow-auto" style={{ height: '250px' }}>
                          {uniqueYears.map(year =>
                            <Button key={year} buttonType={BUTTON_TYPES.FILTER_LIST_ITEM} text={year} onClick={() => handleYearSelect(year, toggle)} iconFlip icon={year === formData.yearFilter ? 'checkmark' : ''} active={year === formData.yearFilter} fullWidth />
                          )}
                        </div>
                      </Card>
                    </div>
                  </ClickOutside>
                )}
              </div>
            )}
          </Toggle>
        )}
      </div>
      <div className="grid grid-gap-md margin-y-md" style={{ height: '300px', overflow: 'scroll' }}>
        {MONTHS.map(month => (
          <Button key={month.name} className={`lucky-button col-6 col-4@sm ${isMonthSelected(month.id) && 'active'}`} buttonType={BUTTON_TYPES.BARE} onClick={() => handleMonthSelect(month.id)} fullWidth disabled={enabledMonthsForActiveDestination && !enabledMonthsForActiveDestination.includes(month.name)}>
            <div className={`padding-md width-100% d8-u-hover-shadow-md`}>
              <div className="flex flex-column justify-between items-center width-100%">
                <Svg name={month.icon} className={'icon--md'} fill={COLORS.BLACK} />
                <div className="text-center margin-top-sm text-component width-100%">
                  <h3 className={`d8-u-font-size-base d8-u-font-weight-500`}>{month.name}</h3>
                  {/* <p className='text-sm d8-u-font-weight-500 d8-u-color-contrast-medium'>2024</p> */}
                </div>
              </div>
            </div>
          </Button>
        ))}
      </div>
    </div>
  );
};

export default Calendar;